// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/for-all-budgets.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-image[data-v-3a2fd05b]{filter:drop-shadow(0 4px 9px #f05225);}@media (min-width: 1024px){.footer-image[data-v-3a2fd05b]{filter:drop-shadow(0 0 0 #f05225)}}.project[data-v-3a2fd05b]{position:relative;font-variant:all-small-caps}@media (min-width: 1024px){.project[data-v-3a2fd05b]:after{content:\"\";width:167px;aspect-ratio:167/109;position:absolute;right:0;bottom:0;transform:translate(78%,-14%);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
