import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=3a2fd05b&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=3a2fd05b&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2fd05b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/components/Button.vue').default,Form: require('/app/components/Form.vue').default,NewsletterForm: require('/app/components/NewsletterForm.vue').default,Footer: require('/app/components/Footer.vue').default})
