//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      socials: [
        {
          image: require("~/assets/img/insta-logo.svg"),
          link: "https://www.instagram.com/nancomcy/",
        },
        {
          image: require("~/assets/img/facebook-logo.svg"),
          link: "https://www.facebook.com/nancomcy/",
        },
        {
          image: require("~/assets/img/linkedin-logo.svg"),
          link: "https://www.linkedin.com/company/nancomcy/",
        },
        {
          image: require("~/assets/img/youtube-logo.svg"),
          link: "https://www.youtube.com/c/AgenceNANCOMCY/",
        },
      ],
      littleMenuItems: [
        {
          label: "Projets",
          link: "projects",
        },
        {
          label: "L'agence",
          link: "agency",
        },
        {
          label: "Contact",
          link: "contact",
        },
        {
          label: "Blog",
          link: "blog",
        },
      ],
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },

  mounted() {
    this.shadowAnimation();
  },

  beforeDestroy() {
    ScrollTrigger.getAll().forEach((t) => t.kill());
  },

  methods: {
    shadowAnimation() {
      const mm = gsap.matchMedia();
      let anim = "";
      mm.add(
        {
          isDesktop: "(min-width: 1024px)",
          isMobile: "(max-width: 1023px)",
        },
        (context) => {
          const { isDesktop } = context.conditions;
          anim = gsap.to(".footer-image", {
            scrollTrigger: {
              trigger: ".footer-image img",
              scrub: true,
              start: "start 90%",
            },
            filter: isDesktop
              ? "drop-shadow(0px 4px 78px #F05225)"
              : "drop-shadow(0px 4px 9px #F05225)",
          });
        }
      );
      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anim.scrollTrigger.refresh();
          }
        });
      });
      const footerImage = document.querySelector(".footer-image");
      io.observe(footerImage);
    },
  },
};
